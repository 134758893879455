<template>
  <v-col cols="12" class="ak-points" @click="goTo(linkName || itemId)">
    <div
      class="ak-points-wrapper"
      :class="[
        { 'd-flex justify-space-between': hasProgressCounts },
        { 'pb-2': hasProgressBar },
      ]"
    >
      <v-row no-gutters>
        <img
          v-if="iconName"
          :src="require(`@/assets/images/home/${iconName}.svg`)"
          class="ak-home-icon pr-3"
        />
        <p class="mb-0 ak-points-title">{{ activityTitle || itemName }}</p>
        <template v-if="informationCounter > 0">
          <div class="ak-points-awards-container">
            <p class="ak-points-awards">
              {{ informationCounter }}
            </p>
          </div>
        </template>
      </v-row>
      <template v-if="hasProgressCounts">
        <p class="mb-0 ak-points-challenge">
          {{ userInfo.challengesCompleted }}/{{ userInfo.challengesTotal }}
        </p>
      </template>
    </div>
    <template v-if="hasProgressBar">
      <v-progress-linear :value="activityProgress" color="#6DB98D" height="25">
        <strong>{{ Math.ceil(activityProgress) }}%</strong>
      </v-progress-linear>
    </template>
  </v-col>
</template>

<script>
export default {
  name: 'Point',

  props: {
    linkName: {
      type: String,
      default: null,
    },
    iconName: {
      type: String,
      default: null,
    },
    activityProgress: {
      type: Number,
      default: 0,
    },
    activityTitle: {
      type: String,
      default: null,
    },
    hasProgressCounts: {
      type: Boolean,
      default: false,
    },
    hasProgressBar: {
      type: Boolean,
      default: false,
    },
    openDetails: {
      type: Boolean,
      default: false,
    },
    itemId: {
      type: String,
      default: null,
    },
    informationCounter: {
      type: Number,
      default: 0,
    },
  },

  methods: {
    goTo(val) {
      if (val !== null) {
        if (this.openDetails) {
          this.$router
            .push({ path: `/learn-unit/${val}`, params: { unitId: val } })
            .catch((err) => {
              console.log(err);
            });
        } else {
          this.$router.push({ name: val }).catch((err) => {
            console.log(err);
          });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.ak-points {
  background-color: var(--v-secondary-base);
  box-shadow: 0px 3px 0px rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  overflow: hidden;
  max-width: 500px;
  margin: auto;
  cursor: pointer;
  max-height: 81px;

  &:not(:last-child) {
    margin-bottom: 20px;
  }

  &-wrapper {
    padding: 31px 21px;
  }

  &-title {
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
  }

  &-challenge {
    font-size: 16px;
    line-height: 19px;
  }

  &-awards {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    text-align: center;
    color: #f1f0ec;
    margin-top: 1px;
  }

  &-awards-container {
    width: 26px;
    height: 26px;
    margin-left: 12px;

    background: #ea1c41;
    border-radius: 50%;
  }

  a {
    text-decoration: none;

    &:focus,
    &:hover,
    &:active {
      outline: none;
    }
  }
}

.ak-home-icon {
  margin-top: -4px;
}
</style>
