<template>
  <v-container class="ak-page-container">
    <PageTitle title="Lerneinheiten" />
    <v-row no-gutters justify="center" class="flex-column mb-10">
      <Point
        v-for="(item, index) in units"
        :key="index"
        :activityProgress="item.progress"
        :activityTitle="item.name"
        :hasProgressBar="true"
        :openDetails="true"
        :itemId="item.id"
      />
    </v-row>
  </v-container>
</template>

<script>
import Point from '@/components/common/Point';
import PageTitle from '@/components/common/PageTitle';

export default {
  name: 'LearningUnitsList',
  components: { Point, PageTitle },
  data() {
    return {
      units: [],
    };
  },

  created() {
    this.getLearningUnits();
  },

  methods: {
    getLearningUnits() {
      this.$axios
        .get(`/api/v1/user/learningunits/`, this.axiosGetConfig)
        .then((res) => {
          if (res.data.data) {
            res.data.data.forEach((item) => {
              this.units.push({
                id: item.data.id,
                name: item.data.name,
                progress: item.data.progress,
              });
            });
          }
          this.$forceUpdate();
        });
    },
  },
};
</script>
